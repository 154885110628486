import React from "react"
import { Container } from "reactstrap"
import { withAuthenticator } from "@aws-amplify/ui-react"

const Attendance = () => {
  return (
    <>
      <Container className="pt-5"></Container>
    </>
  )
}

export default withAuthenticator(Attendance)
